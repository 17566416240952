#home {
  background-image: url('../../images/robo-vision_50.jpeg');
  margin-top: -10%;
  width: 100%;
  height: 950px;
  /* parralax css */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* https://www.callitservices.co.za/wp-content/uploads/2022/10/Vison-bot-min.png' */

/* #login-business {
  margin-top: 150px;
  opacity: 0.7;
  height: 250px;
} 

*/

#home-logo-image-div {
  padding: 5rem;
  margin-top: 450px;
  position: relative;
  overflow: hidden;
}

#home-logo-image-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shooting-star {
  position: absolute;
  top: -600px; /* Start position */
  left: -90px; /* Start position */
  width: 3px;
  height: 250px;
  background: linear-gradient(45deg, white, rgba(255, 255, 255, 0));
  border-radius: 50%;
  transform: rotate(-55deg);
}

.star1 {
  transform: rotate(-55deg);
  animation: shoot1 4s linear infinite;
}

.star2 {
  transform: rotate(-55deg);
  animation: shoot2 8s linear infinite 1s; /* Delayed start */
}

.star3 {
  transform: rotate(-55deg);
  animation: shoot3 3s linear infinite 2s; /* Delayed start */
}

.star4 {
  transform: rotate(-55deg);
  animation: shoot4 9s linear infinite 3s; /* Delayed start */
}

.star5 {
  transform: rotate(-55deg);
  animation: shoot5 6s linear infinite 3.5s; /* Delayed start */
}
.star6 {
  transform: rotate(-55deg);
  animation: shoot6 4s linear infinite 4s; /* Delayed start */
}
.star7 {
  transform: rotate(-55deg);
  animation: shoot7 9s linear infinite 7s;
}
.star8 {
  transform: rotate(-55deg);
  animation: shoot8 7s linear infinite 8s;
}
.star9 {
  transform: rotate(-55deg);
  animation: shoot9 5s linear infinite 9s;
}
@keyframes shoot1 {
  0% {
    top: -1000px;
    right: 0px;
    opacity: 1;
  }
  100% {
    top: 700px; /* Adjust based on container size */
    left: 900px; /* Adjust based on container size */
    opacity: 0;
  }
}

@keyframes shoot2 {
  0% {
    top: -500px;
    right: 0px;
    opacity: 1;
  }
  100% {
    top: 700px; /* Adjust based on container size */
    left: 900px; /* Adjust based on container size */
    opacity: 0;
  }
}
@keyframes shoot3 {
  0% {
    top: -900px;
    right: 0px;
    opacity: 1;
  }
  100% {
    top: 700px; /* Adjust based on container size */
    left: 800px; /* Adjust based on container size */
    opacity: 0;
  }
}
@keyframes shoot4 {
  0% {
    top: -850px;
    right: 0px;
    opacity: 1;
  }
  100% {
    top: 700px; /* Adjust based on container size */
    left: 900px; /* Adjust based on container size */
    opacity: 0;
  }
}

@keyframes shoot5 {
  0% {
    top: -700px;
    right: 0px;
    opacity: 1;
  }
  100% {
    top: 700px; /* Adjust based on container size */
    left: 900px; /* Adjust based on container size */
    opacity: 0;
  }
}

@keyframes shoot6 {
  0% {
    top: -750px;
    right: 0px;
    opacity: 1;
  }
  100% {
    top: 700px; /* Adjust based on container size */
    left: 900px; /* Adjust based on container size */
    opacity: 0;
  }
}

@keyframes shoot7 {
  0% {
    top: -1500px;
    right: 0px;
    opacity: 1;
  }
  100% {
    top: 700px; /* Adjust based on container size */
    left: 900px; /* Adjust based on container size */
    opacity: 0;
  }
}

@keyframes shoot8 {
  0% {
    top: -1000px;
    right: 0px;
    opacity: 1;
  }
  100% {
    top: 700px; /* Adjust based on container size */
    left: 900px; /* Adjust based on container size */
    opacity: 0;
  }
}

@keyframes shoot9 {
  0% {
    top: -1000px;
    right: 0px;
    opacity: 1;
  }
  100% {
    top: 700px; /* Adjust based on container size */
    left: 900px; /* Adjust based on container size */
    opacity: 0;
  }
}

#home-logo-image {
  width: 500px;
}

#login-container {
  padding: 5rem;
  margin-top: 100px;
  opacity: 0.7;
  height: 250px;
}

#user-login-card {
  background-color: rgba(10, 10, 10, 0.258);
  margin-bottom: 50px;
  padding: 1.5rem;
}

#business-login-card {
  background-color: rgba(0, 0, 0, 0.258);
  padding: 1.5rem;
}

#bus {
  background-color: green;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: white;
}

#user {
  background-color: blue;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: white;
}

#sign-in {
  margin-top: 50px;
}

#business {
  display: flex;
  justify-content: center;
}

a {
  color: green;
  text-decoration: none; /* no underline */
}

.sidebar {
  height: 100%;
}

#app-store-container {
  display: flex;
  justify-content: center;
}

#app-store-button {
  margin-top: 20px;
}

/* Global Footer CSS */
.footer {
  background-color: black;
  color: white;
  text-align: center;
}

.footer a {
  text-decoration: none;
  color: grey;
}

@media only screen and (max-width: 768px) {
  .shooting-star {
    display: none;
  }

  #home {
    margin-top: -85px !important;
  }
  #login-container {
    padding: 2rem !important;
    margin-top: 50px !important;
  }

  #app-store-button {
    width: 100% !important;
  }

  #app-store-container {
    margin-bottom: 20px !important;
  }
}
