#sign-in-container {
  display: flex;
  justify-content: center;
}
#sign-in {
  text-align: center;
  width: 45%;
}

@media (max-width: 750px) {
  #sign-in {
    width: 100% !important;
  }
}
