#tech-welcome {
  background-color: #377ab1;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: white;
}

#sign-in-container {
  display: flex;
  justify-content: center;
}
#sign-in {
  text-align: center;
  width: 45%;
}

.MuiGrid-item {
  padding: 5px;
}

#modal-options {
  margin: 0 auto;
}

@media (max-width: 750px) {
  #sign-in {
    width: 100% !important;
  }
}
