#add-client {
  display: flex;
  justify-content: center;
}

#client-details {
  margin-top: 11px;
  color: grey;
  border-radius: 8px;
  width: 100%;
  margin-left: auto;
}

#user-heading {
  color: green;
  border-color: green;
  border-radius: 8px;
  width: 100%;
  background-color: white;
}

#client-info {
  margin-top: 20px;
  border-radius: 8px;
  background-color: rgb(176, 196, 222);
}

#icon {
  float: right;
}
