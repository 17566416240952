#sign-in-container {
  display: flex;
  justify-content: center;
}
#sign-in {
  text-align: center;
  width: 45%;
}

#how-bad-button {
  margin-top: 5px;
  width: 100%;
}

#tickets-box {
  background-color: aliceblue;
  width: 100%;
}

#chat-paper {
  width: 40%;
  margin: 0 auto;
}

#chat-box {
  width: 100%;
}

#chat-grid {
  height: 500px;
  margin-top: 10px;
  width: 100%;
  overflow: auto;
}

#my-text {
  background-color: #2196f3;
  margin-bottom: 5%;
  margin-left: 20%;
  width: 80%;
  text-align: right;
}

#sender {
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 5%;
  width: 80%;
}

#error-btn {
  margin-top: 10px;
}

#welcome {
  background-color: #0b900b;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: white;
}

@media (max-width: 750px) {
  #chat-paper {
    width: auto;
  }

  #sign-in {
    width: 100% !important;
  }

  #my-text {
    background-color: #2196f3;
    margin-bottom: 5%;
    margin-left: 10%;
    width: 80%;
    text-align: right;
  }
}
